import { User } from "model/user";

type AdaSettings = {
	adaReadyCallback: (options: { isRolledOut: boolean }) => void;
};

type AdaEmbed = {
	start(adaSettings?: Record<string, any>): Promise<void>;
	stop(): Promise<void>;
};

declare global {
	interface Window {
		adaSettings: AdaSettings;
		adaEmbed: AdaEmbed;
	}
}

const loadScript = (): Promise<void> =>
	new Promise((resolve, reject) => {
		if (document.getElementById("__ada")) {
			return resolve();
		}

		const script = document.createElement("script");
		script.id = "__ada";
		script.dataset.lazy = "true";
		script.dataset.handle = "ihomefinder";
		script.src = "https://static.ada.support/embed2.js";

		script.onload = () => resolve();
		script.onerror = () => reject(new Error("Failed to load Ada Chatbot"));

		document.head.appendChild(script);
	});

export const toggleChat = async (user?: User | null): Promise<void> => {
	if (!shouldDisplayComponent()) return;
	try {
		if (!user?.permissions?.chat) {
			await window.adaEmbed.stop();
		} else {
			await loadScript();
			await window.adaEmbed.start({ handle: "ihomefinder" });
		}
	} catch(error) {
		// eslint-disable-next-line
		console.error(error);
	}
};

function shouldDisplayComponent(): boolean {
	const currentURL = window.location.href;
	const patterns = ["test2-controlpanel.idxhome.com", "localhost:3000"];
	return patterns.some((pattern) => currentURL.includes(pattern));
}
